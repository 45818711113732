export const zh_CN = {
  主题配置: '主题配置',
  Jan: '一月',
  Feb: '二月',
  March: '三月',
  Apr: '四月',
  May: '五月',
  Jun: '六月',
  July: '七月',
  Aug: '八月',
  Sep: '九月',
  Oct: '十月',
  Nov: '十一月',
  Dec: '十二月',
  分类: '分类',
  销售: '销售',
  市场营销: '市场营销',
  发展: '发展',
  客户支持: '客户支持',
  信息技术: '信息技术',
  行政管理: '行政管理',
  Mon: '周一',
  Thu: '周二',
  Web: '周三',
  Thur: '周四',
  Fri: '周五',
  Sat: '周六',
  Sun: '周日',
  导入: '导入',
  导出: '导出',
  上传配置已应用: '上传配置已应用',
  '上传文件有误，请重新上传': '上传文件有误，请重新上传',
  '您当前浏览器不支持 FileReader，建议使用谷歌浏览器':
    '您当前浏览器不支持 FileReader，建议使用谷歌浏览器',
  白天模式: '白天模式',
  黑夜模式: '黑夜模式',
  female: '女性',
  male: '男性',
  主题色板: '主题色板',
  预览主题: '预览主题',
  系列数量: '系列数量',
  色板预览: '色板预览',
  自定义配色: '自定义配色',
  色板: '色板',
  基础色: '基础色',
  背景色: '背景色',
  辅助色: '辅助色',
  语义红: '语义红',
  语义绿: '语义绿',
  标签: '标签',
  标签字体: '标签字体',
  标签字体描边: '标签字体描边',
  饼图标签: '饼图标签',
  标签高度: '标签高度',
  标签偏移量: '标签偏移量',
  标签牵引线: '标签牵引线',
  坐标轴: '坐标轴',
  '坐标轴(通用)': '坐标轴(通用)',
  轴标签字体: '轴标签字体',
  '轴标签-自动旋转': '轴标签-自动旋转',
  '轴标签-自动省略': '轴标签-自动省略',
  坐标轴线: '坐标轴线',
  网格线: '网格线',
  刻度线: '刻度线',
  网格线对齐刻度线: '网格线对齐刻度线',
  开启子刻度线: '开启子刻度线',
  '坐标轴(下)': '坐标轴(下)',
  轴标题字体: '轴标题字体',
  '坐标轴(左)': '坐标轴(左)',
  '坐标轴(上)': '坐标轴(上)',
  '坐标轴(右)': '坐标轴(右)',
  '坐标轴(circle - 适用于雷达图)': '坐标轴(circle - 适用于雷达图)',
  轴线宽度: '轴线宽度',
  轴线颜色: '轴线颜色',
  网格线类型: '网格线类型',
  '坐标轴(radius 径向轴 - 适用于雷达图)':
    '坐标轴(radius 径向轴 - 适用于雷达图)',
  '图例 - Legend': '图例 - Legend',
  '图例(通用)': '图例(通用)',
  图例项之间的水平间距: '图例项之间的水平间距',
  'marker 默认半径大小': 'marker 默认半径大小',
  图例项文本字体: '图例项文本字体',
  图例项文本行高: '图例项文本行高',
  图例项垂直方向的间隔: '图例项垂直方向的间隔',
  图例组件自己的外边距: '图例组件自己的外边距',
  图例项最大宽度: '图例项最大宽度',
  连续型图例: '连续型图例',
  滑道填充色: '滑道填充色',
  滑道边框: '滑道边框',
  滑道高度: '滑道高度',
  滑道宽度: '滑道宽度',
  滑块颜色: '滑块颜色',
  滑块边框: '滑块边框',
  '滑块宽度（大小）': '滑块宽度（大小）',
  标签字体行高: '标签字体行高',
  标签与滑块的间距: '标签与滑块的间距',
  图例分页器: '图例分页器',
  '分页器 marker': '分页器 marker',
  'marker 非激活态样式': 'marker 非激活态样式',
  'marker 填充色透明度': 'marker 填充色透明度',
  '悬浮提示 - Tooltip': '悬浮提示 - Tooltip',
  'crosshairs 辅助线': 'crosshairs 辅助线',
  辅助线: '辅助线',
  showMarkers: 'showMarkers',
  'marker 大小': 'marker 大小',
  'marker 形状': 'marker 形状',
  'marker 描边': 'marker 描边',
  'marker 阴影色': 'marker 阴影色',
  'marker 阴影模糊度': 'marker 阴影模糊度',
  'marker shadowOffsetX': 'marker shadowOffsetX',
  'marker shadowOffsetY': 'marker shadowOffsetY',
  内容框样式: '内容框样式',
  圆角: '圆角',
  文本样式: '文本样式',
  'Tooltip title 样式': 'Tooltip title 样式',
  'Tooltip list item 样式': 'Tooltip list item 样式',
  'Tooltip marker 样式': 'Tooltip marker 样式',
  'Tooltip value 样式': 'Tooltip value 样式',
  '缩略轴 - Slider': '缩略轴 - Slider',
  标签文字: '标签文字',
  标签字体颜色: '标签字体颜色',
  标签颜色透明度: '标签颜色透明度',
  手柄: '手柄',
  手柄宽度: '手柄宽度',
  手柄高度: '手柄高度',
  手柄填充色: '手柄填充色',
  手柄高亮色: '手柄高亮色',
  手柄描边色: '手柄描边色',
  手柄圆角: '手柄圆角',
  缩略轴前景: '缩略轴前景',
  前景填充色: '前景填充色',
  前景填充色透明度: '前景填充色透明度',
  缩略轴背景: '缩略轴背景',
  背景填充色: '背景填充色',
  背景填充色透明度: '背景填充色透明度',
  '滚动条 - Scrollbar': '滚动条 - Scrollbar',
  滑块: '滑块',
  '滑块 hover 高亮色': '滑块 hover 高亮色',
  滑道: '滑道',
  滑道颜色: '滑道颜色',
};
